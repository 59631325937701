import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Blade.module.css";
import Title from "../../../../components/Title";
import Carrousel from "../../../../components/Carrousel";
import Carousel from 'react-multi-carousel';
import { ListWrapper } from "../../Expositions/Expositions.module.css";
import EventCard from "../../../../components/EventCard";
import BladePhoto1 from "../../../../res/blade/portrait.png"
import BladePhoto2 from "../../../../res/Photos site/Blade/Blade-Martha-Cooper.jpg"
import BladePhoto3 from "../../../../res/Photos site/Blade/Blade graffiti - Henri Chalfant.jpg"
import BladePhoto4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/BLADE, Illegal entry, 1984 BD.jpg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/blade/past-present-1.jpg"
import PastPresent2 from "../../../../res/blade/past-present-2.jpg"
import PastPresent3 from "../../../../res/blade/past-present-3.jpg"
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby"

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Blade",
  name: "Blade",
  description: "Steven D. Ogburn, plus connu sous le pseudonyme de Blade est considéré dans l’histoire du graffiti comme le King of Kings of Graffiti. Né dans le Bronx en 1957, Blade commence le bombing dans les années 1970. C’est en 1972, alors âgé de 15 ans, que son acolyte Hondo 1 l’emmène peindre son premier train dans le Bronx. Il prend rapidement le blaze de Blade, qui signifie lame de cutter en anglais. Il intègre peu à peu le monde du graff et s’entoure des meilleurs pour fonder l’un des plus célèbres groupes de graffeurs : les TC5 (The Crazy Five) où ils peignent la plupart des trains des lignes 2 et 5, qui relient le Bronx et Brooklyn. Son surnom de King of the Kings il le doit aux quelques 5 000 trains qu’il a recouvert de ses graffs. A partir de 1981 Blade commence son travail en atelier. Il arrête définitivement l’art de rue en 1984 pour se consacrer exclusivement aux peintures sur toile et expositions. Ce passage lui permettra notamment d’acquérir une reconnaissance internationale. Il utilise lettres, couleurs, formes géométriques, abstraction et personnages pour développer son propre style ou plutôt ses propres styles. D’ailleurs on remarque souvent que ses peintures ont des allures galactiques avec des constellations et des étoiles filantes en fond. C’est un artiste qui n’a jamais voulu rentrer dans les normes et cherche sans cesse à renouveler ses lettrages. Il est à l’initiative de nouvelles techniques telles que les block letters ou les 3D letters. A la différence de nombreux de ses pairs issus de l’art urbain, ses thématiques ne sont pas engagées mais impliquent directement sa vie personnelle. Comme un journal intime, Blade recouvre les voitures de trains pour exorciser son parcours de vie.",
  pdp: BladePhoto1,
  alt_pdp: "Photo de profil de Blade.",
  photos: [
    { src: PastPresent1, name: "Blade" },
    { src: PastPresent2, name: "Blade" },
    { src: PastPresent3, name: "Blade" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const infos2 = {
  photos: [
    { src: BladePhoto4, name: "Blade", artist: "Blade\n(Steve D. Ogburn dit) (né en 1957)", title:"Illegal entry, 1984", desc1:"Peinture aérosol sur toile", desc2:"Spray paint on canvas", desc3:"183 x 169 cm", desc4:"72 x 66 1⁄2 in.", desc7: "- Collection particulière.", desc8 : "<h4><i>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021.</h4>"  },
  ],
};

const Blade = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
         <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>BLADE <h2 style={{paddingLeft: "16px"}}>(Steven D. Ogburn - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1957</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
                    <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- Self-taught graffiti artist</p>
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Blade: NYC King, Hausammann Gallery, Cortina, Miami, USA, (opening) September 8th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- New York in Gargano: Blade, the King of Graffiti, curated by Marta Gargiulo and Massimo Scrocca, Marina del gargano di Manfredonia (tourist harbour), Siponto, Italy, June 13th - July 12th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- BLADE: King of Graffiti. Photographs, Cards, Drawings, Publications, curated by Roger Gastman, Pijnenburg Gallery, Amsterdam, The Netherlands. (catalogue)</p>
          <br />
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- We Were Here : East Coast x West Coast, Chase Contemporary. New York, USA, June 27th - July 21st .</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Art from the streets, Art Science Museum, Singapore, January 13th - June 3th.</p>
          <p>- Graffiti, Vroom & Varossieau, curated by Heli Harni, Satu Oksanen and Sanna Tuulikangas. Helsinki, Finland, April 6th- September 9th .</p>
          <p>- ICONS II, Vroom & Varossieau, Amsterdam, The Netherlands, April 7th - May 8th.</p>
          <p>- City as Canvas: Graffiti Art From the Martin Wong Collection, Indianapolis Museum of Art,</p>
          <p>Indianapolis, USA, October 5th, 2017 - January 28th, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Francl Gallery, Cannes, France.</p>
          <p>- NYC Legends: BLADE & TRACY 168, Hausammann Gallery, Cortina, Miami, USA, (opening) August 13th.</p>
          <p>- 212 Gallery, Wynwood, Miami, USA (Art Basel).</p>
          <p>- ALL BIG LETTERS, curated by RJ Rushmore, Cantor Fitzgerald Gallery, Haverford, USA, January 20th - March 3rd.</p>
          <p>- Digard Auction House, Paris</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Urban Dawn II, by art platform curator19.90, Beirut, Lebanon, October 15th - November 13th.</p>
          <p>- City as Canvas: Graffiti Art From the Martin Wong Collection, Museum of the History of Bologna, Bologna, Italy, March 18th - June 26th. (touring exhibition).</p>
          <p>- City as Canvas: Graffiti Art From the Martin Wong Collection, Amsterdam Museum. Amsterdam, The Netherlands, September 18th, 2015 - January 24, 2016. (touring exhibition).</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Foggia & Manfredonia, Italy</p>
          <p>- Fuck the King, Long Live the Kings, Blade, Inkie and Shoe, Vroom & Varossieau, Amsterdam, The Netherlands.</p>
          <p>- Fuck the King, Long Live the Kings, Blande, Inkie and Shoe, Gothenburg, Sweden. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- City as Canvas: Graffiti Art From the Martin Wong Collection, Museum of the City of New York, USA, February 4th - September 21st (touring exhibition) (catalogue).</p>
          <p>- Jonathan Levine Gallery, New York, USA.</p>
          <p>- Galleria Varsi, Rome, Italy.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Viborg Billboard Festival, Galleri NB, Denmark, June 1st - June 8th.</p>
          <p>- Articks Gallery, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Adjust Gallery, Art Basel, Miami, USA, December 6th - 9th.</p>
          <p>- Speerstra Foundation (Collection Speerstra), Apples, Switzerland.</p>
          <p>- Graffiti - New York 80's, Galerie Jérôme de Noirmont, Paris, France, May 27th - July 20th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Group Show: Hall of Fame, Speerstra Gallery, Geneva, Switzerland, November 8th - December 12th.</p>
          <p>- Melange of Street Art Artists, Galerie Helenbeck, Nice, France, July 9th - September 10th.</p>
          <p>- Art in the Streets, MOCA, Los Angeles, USA, April 17th - August 8th. (catalogue)</p>
          <p>- Articks gallery, Amsterdam, The Netherlands.</p>
          <p>- Gallery Vlasblom, Bergen, Amsterdam, The Netherlands.</p>
          <p>- Carmichael Gallery, Los Angeles, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Gismondi Galerie, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Galerie Gismondi and Helenbeck Galerie, Paris, France.</p>
          <p>- Helenbeck Galerie, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Brooklyn Library, Brooklyn, USA.</p>
          <p>- Old School Legends, The Outside Institute, London, UK. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Down Under Tour, Auckland, New Zealand and Brisbane; Melbourne, Australia.</p>
          <p>- Blade, Cope II, Pistol, Aurora Gallery, New York, USA.</p>
          <p>- Marco Art Galerie, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Blade with Seen, Revolt, Chino and Pistol, Marco Art Galerie, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Marco Art Galerie, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Whitney Museum, New York, USA.</p>
          <p>- Brooklyn Museum, Brooklyn, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Bronx Museum, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- The Arrow, by the collective Stocktown,in conjecture with Fanclub festival, Stockholm, Sweden, July 24th - 26th.</p>
          <p>- Museum der Stadt Ratingen, Ratingen, Germany.</p>
          <p> - Blade ONE / Case 2: Return to Burn, Martinez Gallery, New York, USA, May 16th - June 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Symposium, Museum of the City of New York, USA.</p>
          <p>- Wiesbaden Festival, Wiesbaden, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- The Stedelijk Museum Roermond, Roermond, The Netherlands.</p>
          <p>- Drawings Show, South Of France.</p>
          <p>- Galerie City, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Boymans Museum, Germany.</p>
          <p>- Museum Dortmund, Germany.</p>
          <p>- Galerie Heidelberg, Germany.</p>
          <p>- Düsseldorf Festival, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Gallery Amsterdam, The Netherlands.</p>
          <p>- Castle Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Museum Show, Rotterdam Museum, Rotterdam, The Netherlands.</p>
          <p>- City Gallery, Brussels, Belgium.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- New York Graffiti Art : Coming from the Subway (Collection Speerstra), Groninger Museum, Groningen, The Netherlands, October 4th, 1992 - January 10th, 1993. (catalogue)</p>
          <p>- Gemeentemuseum Den Haag, Den Haag, The Netherlands.</p>
          <p>- City Gallery, Milan, Italy.</p>
          <p>- Groninger Museum Show, Groninger Museum, Groningen, The Netherlands.</p>
          <p>- The Mannheimer Kunstverein, Mannheim, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Graffiti Art : Artiste américains et français 1981 / 1991(Collection Speerstra), Musée des monuments Français Paris, Paris, France. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands.</p>
          <p>- Nassauischer Kunstverein Wiesbaden, Wiesbaden, Germany.</p>
          <p>- Heidelberger Kunstverein, Heidelberg, Germany.</p>
          <p>- Helmond Museum & Art Gallery, Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- De Horizon Voorbij, Gemeentemuseum Den Haag, Den Haag, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Galerie Thomas, München, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- New York Graffiti, Leopold-Hoesch museum, Amsterdam, The Netherlands, March 9th - May 4th (catalogue).</p>
          <p>- Helmond Museum & Art Gallery, Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Galerie Yaki Kornblit, Amsterdam, The Netherlands.</p>
          <p>- New York Graffiti, Gemeentemuseum Den Haag, Den Haag, The Netherlands.</p>
          <p>- Stellweg Seguy Gallery, New York, USA.</p>
          <p>- Museum Helmond, Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- New York Graffiti (Collection Speerstra), Louisiana Museum, Humlebaek, Denmark, September 7th - October 7th.</p>
          <p>- Graffiti, Groninger Museum. Groningen, The Netherlands, January 14th - February 26th. (catalogue)</p>
          <p></p>
          <p>- Galerie Yaki Kornblit, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Graffiti, Museum Boijmans, Van Beuningen, Rotterdam, The Netherlands, October 22nd - December 4th (catalogue).</p>
          <p>- Post-Graffiti, Sidney Janis Gallery, New York, USA, December 1st - 31st. (catalogue), München, Germany.</p>
          <p>- Galerie Yaki Kornblit, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p id="end">- New York/ New Wave, organized by Diego Cortez, P.S.1., Institute for Art and Urban Ressources, Long Island City, USA, February 15th - April 5th.</p>
          </div>
        </ul>
        <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Blade;