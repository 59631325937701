// extracted by mini-css-extract-plugin
export var ArtistDescription = "Blade-module--ArtistDescription--o-2AR";
export var ArtistInfos = "Blade-module--ArtistInfos--eKGtB";
export var ButtonWrapper = "Blade-module--ButtonWrapper --VEn0N";
export var CardWrapper = "Blade-module--CardWrapper--Z3Kej";
export var CarrouselWrapper2 = "Blade-module--CarrouselWrapper2--nrfYY";
export var Citations = "Blade-module--Citations--O+rMs";
export var DescriptionWrapper = "Blade-module--DescriptionWrapper--ZRjtr";
export var ImageWrapper = "Blade-module--ImageWrapper--XcPhd";
export var LinkWrapper = "Blade-module--LinkWrapper--pWO0I";
export var MobileProtrait = "Blade-module--MobileProtrait--FLWqZ";
export var More = "Blade-module--More--dq6Ii";
export var MoreButton = "Blade-module--MoreButton--ksrc8";
export var NameWrapper = "Blade-module--NameWrapper--uw8o-";
export var PdpWrapper = "Blade-module--PdpWrapper--swnu0";
export var PhotosWrapper = "Blade-module--PhotosWrapper--xSEaX";
export var ProfilWrapper = "Blade-module--ProfilWrapper--9jZDt";
export var TitleWrapper = "Blade-module--TitleWrapper---Qkag";
export var Wrapper = "Blade-module--Wrapper--lm8Ml";